<template>
  <Outer>
    <div class="b-auth">
      <h2 class="b-title _center">
        <slot name="title"></slot>
      </h2>

      <div class="b-text _note _center">
        <slot name="note"></slot>
      </div>

      <div v-if="canResend" class="b-row _centered _space_below _space_above">
        <div v-if="timer > 0" class="b-text _small_action _center">
          {{
            $t('auth---You can receive the code again in {timer} s.', {
              timer: timer,
            })
          }}
        </div>
        <a v-else href="#" class="b-link" @click.prevent="resendCode">
          {{ $t('auth---Send code again') }}
        </a>
      </div>

      <form class="b-form _code" @submit.prevent="handleSubmit">
        <div class="b-row _space_above_20">
          <Code />
          <div class="b-error" v-if="errors.code" v-html="errors.code"></div>
        </div>

        <div class="b-row _space_above _space_below">
          <button class="b-button _full-width" type="submit" :disabled="!allowSubmit || needRelogin">
            <slot name="submit">
              {{ $t('auth---Continue') }}
            </slot>
          </button>
        </div>

        <template v-if="timerResendCode <= 0">
          <div class="b-row _space_above _centered">
            <a @click.prevent:="resendVerificationCode()" href="#" class="b-link">{{ $t('auth---Get new code') }}</a>
          </div>
        </template>

        <template v-else>
          <div class="b-row _space_above _centered">
            {{ $t('auth---Get new code') }} <span class="resend-code-timer">{{ timerResendCodeFormatted }}</span>
          </div>
        </template>

        <div class="b-row _space_above _centered">
          <a href="#"  @click.prevent="$router.push({ name: 'auth-verification-code-not-received' })" class="b-link">{{ $t('auth---Code not received') }}</a>
        </div>

        <div v-if="submitError" class="b-row _error">
          <code>{{ submitError }}</code>
        </div>

        <div v-if="canResend" class="b-row _centered _space_above">
          <a
            href="#"
            class="b-link"
            @click.prevent="$router.push({ name: 'auth-reset-admins' })"
            >{{ $t('auth---I have not received the SMS') }}
          </a>
        </div>
        <div>
        </div>
      </form>
    </div>
  </Outer>
</template>
<i18n src="../i18n/translations/codePage.json"></i18n>

<script>
import Outer from './Outer.vue';
import Code from './Code.vue';
import {mapMutations, mapState} from 'vuex';

const TIMER = 30;

export default {
  components: {
    Outer,
    Code,
  },
  props: {
    canResend: Boolean,
  },
  data() {
    return {
      timer: 0,
      timerResendCode: 0,
      timerResendCodeFunction: null,
    };
  },
  mounted() {
    document.title = this.$i18n.t('auth---Verification code');
    this.$store.dispatch('getVerificationCodeDelay').then(result => {
      this.resetTimerUpdateCode(result.item.delay);
    });
    this.resetTimer();
  },
  computed: {
    ...mapState({
      errors: (state) => state.errors,
      submitError: (state) => state.submitError,
      needRelogin: (state) => state.needRelogin,
    }),
    codeString() {
      let codeNumbers = '';
      if (Array.isArray(this.$store.state.code)) {
        const filteredCode = this.$store.state.code.filter((codeItem) => /^([0-9])$/.test(codeItem));
        for (const codeItem of filteredCode) {
          codeNumbers += codeItem;
        }
      }
      return codeNumbers;
    },
    timerResendCodeFormatted() {
     const minutes = Math.floor(this.timerResendCode / 60);
     const seconds = this.timerResendCode % 60;

      const timerFormat = Intl.NumberFormat('en-US', {
        minimumIntegerDigits: 2,
      });

      return `${timerFormat.format(minutes)}:${timerFormat.format(seconds)}`;
    },
    allowSubmit() {
      return this.codeString && this.codeString.length === 6;
    },
  },
  methods: {
    ...mapMutations(['code']),
    resetTimer() {
      this.timer = TIMER;
      const id = setInterval(() => {
        if (this.timer < 0) {
          clearInterval(id);
        }
        this.timer -= 1;
      }, 1000);
    },
    resetTimerUpdateCode(newTimer) {
      this.timerResendCode = newTimer;

      if (this.timerResendCodeFunction !== null) {
        clearInterval(this.timerResendCodeFunction);
      }

      this.timerResendCodeFunction = setInterval(() => {
        if (this.timerResendCode < 0) {
          clearInterval(this.timerResendCodeFunction);
          this.timerResendCodeFunction = null;
        }
        this.timerResendCode =  this.timerResendCode - 1;
      }, 1000);
    },

    async resendVerificationCode() {
      const response = await this.$store.dispatch('resendVerificationCode');
      const { delay } = response.item;
      this.resetTimerUpdateCode(delay);
    },
    resendCode() {
      this.resetTimer();
      this.$emit('codeResend');
    },
    handleSubmit() {
      this.$emit('codeSubmit', this.codeString);
    }
  },
  created() {
    this.code([null, null, null, null, null, null]);
  }
};
</script>
