<template>
  <CodePage
    can-resend
    v-on:codeSubmit="handleCode"
    v-on:codeResend="this.smsRecover"
  >
    <template #title v-if="username">
      {{
        $t('auth---Do not worry, {username}!', { username: username })
      }}
    </template>

    <template #note>
      {{
        $t('auth---Enter code received via SMS on your phone number {phone}.', {
          phone: maskedPhone
        })
      }}
    </template>
  </CodePage>
</template>

<i18n src="../i18n/translations/reset.json"></i18n>

<script>
import CodePage from '../components/CodePage.vue';
import {mapActions, mapState} from 'vuex';

export default {
  components: {
    CodePage,
  },
  data() {
    return {
    };
  },
  mounted() {
    document.title = this.$i18n.t('auth---Reset by sms');
  },
  computed: {
    ...mapState({
      username: (state) => state.username,
      userId: (state) => state.userId,
      maskedPhone: (state) => state.maskedPhone,
    }),
  },
  methods: {
    ...mapActions(['smsRecover']),
    handleCode(code) {
      this.$router.push({ name: 'auth-reset', query: { userId: this.userId, code: code} });
    },
  }
};
</script>
