<template>
  <div class="b-input-code">
    <input
      v-for="(item, index) of length"
      :ref="`input_${index}`"
      :key="index"
      class="b-input _code"
      :class="{ _error: !!errors.code }"
      placeholder="_"
      :value="code[index]"
      @input="inputCode($event, index)"
      @keyup="deleteCode($event, index)"
      @paste.stop.prevent="pasteCode($event, index)"
      @focus="$event.target.select()"
      @mousedown.stop.prevent="$event.target.select()"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  props: {
    addedClass: {
      type: String,
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.errors,
      code: (state) => state.code,
      length: (state) => state.code.length,
    }),
  },
  methods: {
    ...mapActions(['setCode', 'setError']),
    _setCode({ code, codeArray, index }) {
      let nextIndex = index + 1;
      // Prepare value
      if (!codeArray) {
        codeArray = Object.assign([], code);
        codeArray = codeArray.filter((c) => /^([0-9])$/.test(c));
      }
      const value = codeArray[0] || null;
      // Save
      this.setCode({ index, value });

      // Paste to the next input.
      if (codeArray.length > 1) {
        codeArray = codeArray.slice(1);
        this._setCode({ codeArray, index: nextIndex });
      }

      // Focus into the next input.
      else if (value) {
        nextIndex =
            nextIndex < 0
            ? 0
            : nextIndex >= this.length
            ? this.length - 1
            : nextIndex;
        const ref = `input_${nextIndex}`;
        this.$refs[ref][0].focus();
      }

      return value;
    },
    inputCode(event, index) {
      const value = event.target.value;
      let codeArray = Object.assign([], value)
        .filter((c) => /^([0-9])$/.test(c))
        .slice(-1);
      const code = codeArray[0] || null;
      this._setCode({ code, index });
    },
    pasteCode(event, index) {
      const clipboardData = event.clipboardData || window.clipboardData;
      const code = clipboardData.getData('Text');
      this._setCode({ code, index });
    },
    deleteCode(event, index) {
      if (event.code === 'Backspace' || event.code === 'Delete') {
        let prevIndex = index - 1 <= 0 ? 0 : index - 1;
        const ref = `input_${prevIndex}`;
        this.$refs[ref][0].focus();
      }
    },
  },
};
</script>
